/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { useQuery } from 'react-query';
import { getOrderList } from 'api/admin';

function Order() {
  const {
    data: orders,
    isError,
    isLoading,
    error,
  } = useQuery<Order[], Error>('orders', getOrderList);

  if (isLoading) {
    return <>Loading...</>;
  }

  if (isError) {
    return <p>{error?.message}</p>;
  }

  return (
    <>
      Order list
      <ul>
        {orders?.map((order) => (
          <li>
            {order.order_id} | {order.customer_id} | {order.final_amount}
          </li>
        ))}
      </ul>
    </>
  );
}

export default Order;
