/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { useQuery } from 'react-query';
import { getCustomerList } from 'api/admin';

function Customers() {
  const {
    data: customers,
    isError,
    isLoading,
    error,
  } = useQuery<Customer[], Error>('customers', getCustomerList);

  if (isLoading) {
    return <>Loading...</>;
  }

  if (isError) {
    return <p>{error?.message}</p>;
  }

  return (
    <>
      Customer list
      <ul>{customers?.map((customer) => <li>{customer.name}</li>)}</ul>
    </>
  );
}

export default Customers;
