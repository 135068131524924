/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { useQuery } from 'react-query';
import { getUserList } from 'api/admin';

function Order() {
  const {
    data: users,
    isError,
    isLoading,
    error,
  } = useQuery<User[], Error>('users', getUserList);

  if (isLoading) {
    return <>Loading...</>;
  }

  if (isError) {
    return <p>{error?.message}</p>;
  }

  return (
    <>
      User list
      <ul>
        {users?.map((user) => (
          <li>
            {user.email} | {user.name} | {user.phone}
          </li>
        ))}
      </ul>
    </>
  );
}

export default Order;
