/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { useQuery } from 'react-query';
import { getProductList } from 'api/admin';

function Products() {
  const {
    data: products,
    isError,
    isLoading,
    error,
  } = useQuery<Product[], Error>('products', getProductList);

  if (isLoading) {
    return <>Loading...</>;
  }

  if (isError) {
    return <p>{error?.message}</p>;
  }

  return (
    <>
      Product list{' '}
      <li>{products?.map((product) => <li>{product.name}</li>)}</li>
    </>
  );
}

export default Products;
