// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';

const adminApi = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_API_BASE_URL,
});

export const getProductList = async (): Promise<Product[]> => {
  const response = await adminApi.get('/products');
  return response?.data?.data;
};

export const getBrandList = async (): Promise<BrandResponse> => {
  const response = await adminApi.get('/brand');
  return response?.data;
};

export const getCategoryList = async (): Promise<Category[]> => {
  const response = await adminApi.get('/category');
  return response?.data?.data;
};

export const getCustomerList = async (): Promise<Customer[]> => {
  const response = await adminApi.get('/customers');
  return response?.data?.data;
};

export const getOrderList = async (): Promise<Order[]> => {
  const response = await adminApi.get('/orders');
  return response?.data?.data;
};

export const getUserList = async (): Promise<User[]> => {
  const response = await adminApi.get('/users');
  return response?.data?.data;
};

export default adminApi;
