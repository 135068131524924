/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { useQuery } from 'react-query';
import { getBrandList } from 'api/admin';

function Brands() {
  const {
    data: brandData,
    isError,
    isLoading,
    error,
  } = useQuery<BrandResponse, Error>('brands', getBrandList);

  if (isLoading) {
    return <>Loading...</>;
  }

  if (isError) {
    return <p>{error?.message}</p>;
  }

  return (
    <>
      Brand list
      <ul>{brandData?.data?.map((brand) => <li>{brand?.brand_name}</li>)}</ul>
    </>
  );
}

export default Brands;
