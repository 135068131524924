/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { useQuery } from 'react-query';
import { getCategoryList } from 'api/admin';

function Category() {
  const {
    data: categories,
    isError,
    isLoading,
    error,
  } = useQuery<Category[], Error>('categories', getCategoryList);

  if (isLoading) {
    return <>Loading...</>;
  }

  if (isError) {
    return <p>{error?.message}</p>;
  }

  return (
    <>
      Category list
      <ul>
        {categories?.map((category) => <li>{category.category_name}</li>)}
      </ul>
    </>
  );
}

export default Category;
