import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
  Link,
} from 'react-router-dom';

import AppLayout from './components/AppLayout';
import Customer from './components/Customer';
import Product from './components/Product';
import Order from './components/Order';
import User from './components/User';
import Brand from './components/Brand';
import Category from './components/Category';

function UserDetail() {
  const { userID } = useParams();
  return <>User ID = {userID}</>;
}

function Home() {
  return (
    <>
      Welcome to artzimar website
      <ul>
        <li>
          <Link to="/dashboard">Dashboard</Link>{' '}
        </li>
        <li>
          <Link to="/customers">Customers</Link>
        </li>
        <li>
          <Link to="/products">Products</Link>
        </li>
        <li>
          <Link to="/orders">Orders</Link>
        </li>
        <li>
          <Link to="/users">Users</Link>
        </li>
      </ul>
    </>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/dashboard" element={<>Hello this is dashboard </>} />
        <Route path="/customers" element={<Customer />} />
        <Route path="/products" element={<Product />} />
        <Route path="/orders" element={<Order />} />
        <Route path="/users/" element={<User />} />
        <Route path="/users/:userID" element={<UserDetail />} />
        <Route path="/admin" element={<AppLayout />}>
          <Route path="brand" element={<Brand />} />
          <Route path="category" element={<Category />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
